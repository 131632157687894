import React, { Fragment } from 'react';

const VisibleContainer = ({ visible, children }) => {
  if (!visible) {
    return null;
  }
  {
    return children;
  }
};

export default VisibleContainer;
