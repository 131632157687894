import store from '../store';
import { ApiCall } from '../services';
import { Envs } from '../config';

export default {
  state: {
    providerDetails: null,
  },
  /**
   * Reducers
   */
  reducers: {
    getProviderDetailsReducer(state, data) {
      return {
        ...state,
        providerDetails: data,
      };
    },
  },

  /**
   * Effects/Actions
   */
  effects: {
    async getProviderDetails({ payload, callback }) {
      const apiCall = new ApiCall();
      try {
        apiCall
          .getProviderDetails(payload)
          .then((response) => {
            if (response.status === 200) {
              const data = response.data;
              this.getProviderDetailsReducer(data['data']);
              callback(data, null);
            }
          })
          .catch((err) => {
            callback(null, err);
            this.getProviderDetailsReducer('error');
            console.log('Err getProviderDetails: ', err);
          });
      } catch (err) {
        console.log('Err getProviderDetails: ', err);
      }
    },
  },
};
