import React from 'react';
import { Select, SelectItem } from '@nextui-org/react';
import { emptyFunc } from '../services';
// import './SingleSelectDropdown.css';

const SingleSelectDropdown = ({
  items,
  label,
  value,
  disabled,
  marginTop,
  marginBottom,
  onChange = emptyFunc,
}) => {
  // console.log('items: ', items);
  const handleSelectionChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <Select
      label={label}
      className="max-w-xs"
      selectedKeys={[value]}
      onChange={handleSelectionChange}
      style={{ marginTop, marginBottom }}
    >
      {items.map((item, index) => (
        <SelectItem key={item['value']}>{item.label}</SelectItem>
      ))}
    </Select>
  );
};

export default SingleSelectDropdown;
