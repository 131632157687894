//no-unused-vars
import React, { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { NextUIProvider } from '@nextui-org/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HomePage, PlaygroundPage } from './pages';
import localDevVariables from './localDevVariables';
import { setApiTeamId } from './services';
import './bootstrap-grid.min.css';
import store from './store';
import { BackdropSpinner, Snackbar, Error } from './components';

const theme = createTheme();
const App = () => {
  useEffect(() => {}, []);

  if (localDevVariables.env === 'playground') {
    return (
      <StoreProvider store={store}>
        {/* <ThemeProvider theme={theme}> */}
        <Snackbar />
        <PlaygroundPage />
        {/* </ThemeProvider> */}
      </StoreProvider>
    );
  }
  return (
    <StoreProvider store={store}>
      {/* <ThemeProvider theme={theme}> */}
      <NextUIProvider>
        <BackdropSpinner />
        <BrowserRouter>
          <Snackbar />
          <Routes>
            <Route
              path="/"
              element={
                <Error
                  showReloadButton
                  title="Wrong URL provided, please verify or contact your provider."
                />
              }
            />
            <Route
              path="/:provider_slug"
              element={<HomePage type="provider" />}
            />
            <Route
              path="/clinic/:team_slug"
              element={<HomePage type="clinic" />}
            />
            <Route
              path="/redirect/:provider_slug"
              element={<HomePage type="redirect" />}
            />
          </Routes>
        </BrowserRouter>
      </NextUIProvider>

      {/* </ThemeProvider> */}
    </StoreProvider>
  );
};

const initSetup = () => {
  setApiTeamId();
};
initSetup();

export default App;
