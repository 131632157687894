import axios from "axios";
import store from "../store";
import { Envs } from "../config";
import { ApiCall } from "../services";

export default {
  state: {},
  /**
   * Reducers
   */
  reducers: {},

  /**
   * Effects/Actions
   */
  effects: {},
};
