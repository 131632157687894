import React from 'react';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import './PrimaryButton.css';

const PrimaryButton = ({
  label,
  loading,
  fullWidth,
  onClick,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  variant = 'contained',
}) => {
  if (loading) {
    return (
      <div style={{ marginBottom, marginTop, marginLeft, marginRight }}>
        <LoadingButton
          loading
          loadingPosition="start"
          fullWidth={fullWidth}
          variant={variant}
          startIcon={<></>}
        >
          {label && label}
        </LoadingButton>
      </div>
    );
  }
  return (
    <div style={{ marginBottom, marginTop, marginLeft, marginRight }}>
      <Button onClick={() => onClick()} fullWidth={fullWidth} variant={variant}>
        {label && label}
      </Button>
    </div>
  );
};

export default PrimaryButton;
