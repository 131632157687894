import React, { useEffect, useState } from 'react';
import { emptyFunc, capitalizeFirstLetter } from '../services';
import { Select, SelectSection, SelectItem } from '@nextui-org/react';
import { Colors, Images } from '../config';
// import './PackageDropdown.css';

const PackageDropdown = ({
  items,
  label,
  value,
  disabled,
  marginTop,
  marginBottom,
  onChange = emptyFunc,
}) => {
  // console.log('items: ', items);

  const headingClasses =
    'flex w-full sticky top-1 z-20 py-1.5 px-2 bg-default-100 shadow-small rounded-small';
  const handleSelectionChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <Select
      label={label}
      className="max-w-xs"
      selectedKeys={[value]}
      onChange={handleSelectionChange}
      style={{ marginTop, marginBottom }}
      scrollShadowProps={{
        isEnabled: false,
      }}
    >
      {items.map((item_s, indexes) => {
        return (
          <SelectSection
            key={indexes}
            title={item_s['name']}
            classNames={{
              heading: headingClasses,
            }}
          >
            {item_s['items'].map((item, index) => {
              if (!!item['active']) {
                return (
                  <SelectItem key={item['id']}>
                    {item['appointment_name']}
                  </SelectItem>
                );
              }
            })}
          </SelectSection>
        );
      })}
    </Select>
  );
};
export default PackageDropdown;
