import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Button,
  Grid,
  Box,
  Radio,
  FormGroup,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { AccessTime, CurrencyRupee } from '@mui/icons-material';
import {
  Loader,
  Error,
  IconLabel,
  PackageDropdown,
  ArrowBackButton,
  VisibleContainer,
  PrimaryTextInput,
  SingleSelectDropdown,
} from '../../../components';
import { isBrowser } from 'react-device-detect';
import { Colors } from '../../../config';
import './PreFormUI.css';

const PreFormUI = ({
  type,
  team,
  amount,
  image,
  name,
  overview,
  packageDesc,
  duration,
  aptType,
  aptChoice,
  newPatient,
  isCashPayment,
  providerDetails,
  onProviderChange,
  onCashPaymentChange,
  selectedPsychDetails,
  onNewPatientChange,
  onAptChoiceChange,
  onAptTypeChange,
  onLocationChange,
  locations,
}) => {
  // console.log('selectedPsychDetails: ', selectedPsychDetails);
  // console.log('providerDetails: ', providerDetails);
  // console.log('team: ', team);

  const [isReadMore, setIsReadMore] = useState(false);
  const [isAboutReadMore, setIsAboutReadMore] = useState(false);

  const getProviderDropdown = () => {
    let tempArray = [];
    providerDetails.forEach((item, index) => {
      let tempIsActive = false;
      item['psych_charges'].forEach((it, ind) => {
        if (it['active']) {
          tempIsActive = true;
        }
      });
      if (tempIsActive) {
        tempArray.push({
          label: item.psych_details['name'],
          value: item.psych_details['id'].toString(),
        });
      }
      tempIsActive = false;
    });
    return tempArray;
  };
  const getTypeOfApt = (psych_charges_with_packages) => {
    let finalArray = [];
    if (!!psych_charges_with_packages) {
      const entryKeys = Object.keys(psych_charges_with_packages);
      entryKeys.forEach((item) =>
        finalArray.push(psych_charges_with_packages[item])
      );
    }
    return finalArray;
  };
  const getlocations = (psych_location) => {
    let tempArray = [];
    psych_location.forEach((item, index) => {
      tempArray.push({
        label: item.location_name,
        value: item.location_id,
      });
    });
    return tempArray;
  };

  if (!selectedPsychDetails) return;
  if (
    !selectedPsychDetails.psych_settings['allow_public_appointment'][
      'display'
    ] ||
    selectedPsychDetails['psych_charges_with_packages'].length == 0 ||
    (selectedPsychDetails.psych_settings['cash_payment'] == 0 &&
      selectedPsychDetails['psych_details']['razorpay_account_id'] == null)
  ) {
    return (
      <div
        className="flex-class"
        style={{
          marginLeft: isBrowser ? '' : '8px',
          marginRight: isBrowser ? '' : '8px',
          // paddingLeft: isBrowser ? '12px' : '',
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        <img
          style={{ width: 100, height: 100, borderRadius: 400 / 2 }}
          src={
            selectedPsychDetails.psych_details['psychiatrist_details'][
              'profile_image_url'
            ]
              ? selectedPsychDetails.psych_details['psychiatrist_details'][
                  'profile_image_url'
                ]
              : 'https://s3.us-west-2.amazonaws.com/psychonline.upload/defaultpic.png'
          }
        />
        <p style={{ color: Colors.black, textAlign: 'left' }}>
          <b>Provider Name:</b> {selectedPsychDetails.psych_details['name']}
        </p>
        <p
          style={{
            color: Colors.black,
            textAlign: 'left',
            paddingRight: '10px',
          }}
        >
          <b>About:</b>{' '}
          {
            selectedPsychDetails.psych_details['psychiatrist_details'][
              'overview'
            ]
          }
        </p>
        <p style={{ color: Colors.danger, textAlign: 'center' }}>
          {selectedPsychDetails['psych_charges_with_packages'].length == 0
            ? 'The provider has not enabled the fees setting, please contact provider for more details.'
            : selectedPsychDetails.psych_settings['allow_public_appointment'][
                'message'
              ]}
          {selectedPsychDetails.psych_settings['cash_payment'] == 0 &&
          selectedPsychDetails['psych_details']['razorpay_account_id'] == null
            ? 'Please enable Razorpay account or cash booking.'
            : ''}
        </p>
        <FormControl fullWidth>
          {type == 'clinic' && providerDetails.length > 1 ? (
            <SingleSelectDropdown
              marginTop={8}
              marginBottom={8}
              label="Select provider*"
              items={getProviderDropdown()}
              value={selectedPsychDetails.psych_details['id']}
              onChange={(value) => onProviderChange(value)}
            />
          ) : null}
        </FormControl>
      </div>
    );
  }
  return (
    <div
      className="flex-class"
      style={{
        marginLeft: isBrowser ? '' : '8px',
        marginRight: isBrowser ? '' : '8px',
        // paddingLeft: isBrowser ? '12px' : '',
        paddingLeft: '15px',
        paddingRight: '15px',
      }}
    >
      <img
        style={{ width: 100, height: 100, borderRadius: 400 / 2 }}
        src={
          image
            ? image
            : 'https://s3.us-west-2.amazonaws.com/psychonline.upload/defaultpic.png'
        }
      />
      <p style={{ color: Colors.black, textAlign: 'justify' }}>
        <b>
          {team == 39 || team == 40 || team == 41 || team == 42
            ? 'Doctor Name: '
            : 'Provider Name: '}
        </b>
        {`${name} `}
      </p>
      {overview ? (
        <p
          style={{
            color: Colors.black,
            textAlign: 'justify',
            paddingRight: '10px',
          }}
        >
          <b>About:</b>
          {isAboutReadMore ? overview : overview.substring(0, 140)}
          <span
            onClick={() => setIsAboutReadMore(!isAboutReadMore)}
            className="read-more-btn"
          >
            {isAboutReadMore ? 'Read less' : 'Read more...'}
          </span>
        </p>
      ) : (
        <p
          style={{
            color: Colors.red,
            textAlign: 'justify',
            paddingRight: '10px',
          }}
        >
          <b>About:</b>
          No information found, please update your profile. It will help your
          patients to know more about you.
        </p>
      )}
      <IconLabel
        label={`${duration} min`}
        Icon={<AccessTime />}
        marginBottom="8px"
      />
      <IconLabel
        label={`${amount} INR`}
        Icon={<CurrencyRupee />}
        marginBottom="8px"
      />
      <FormControl fullWidth>
        {type == 'clinic' && providerDetails.length > 1 ? (
          <SingleSelectDropdown
            marginTop={8}
            marginBottom={8}
            label={
              team == 39 || team == 40 || team == 41 || team == 42
                ? 'Select doctor*'
                : 'Select provider*'
            }
            items={getProviderDropdown()}
            value={selectedPsychDetails.psych_details['id']}
            onChange={(value) => onProviderChange(value)}
          />
        ) : null}
        <FormLabel id="demo-radio-buttons-group-label">
          Appointment Choice*
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={aptChoice}
        >
          {selectedPsychDetails['choice_of_appointment'].map((item, index) => {
            if (item['checked']) {
              return (
                <FormControlLabel
                  key={index}
                  onChange={(event) => onAptChoiceChange(event.target.value)}
                  value={item.value.toString()}
                  control={<Radio />}
                  label={item['label']}
                />
              );
            }
          })}
        </RadioGroup>
      </FormControl>

      <FormControl fullWidth>
        <FormLabel id="demo-radio-buttons-group-label">
          Patient status*
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={!!newPatient ? 'new' : 'old'}
        >
          <FormControlLabel
            onChange={(event) => onNewPatientChange(event.target.value)}
            value="new"
            control={<Radio />}
            label="New"
          />
          <FormControlLabel
            onChange={(event) => onNewPatientChange(event.target.value)}
            value="old"
            control={<Radio />}
            label="Exisiting"
          />
        </RadioGroup>
      </FormControl>
      <VisibleContainer
        visible={
          selectedPsychDetails.psych_settings['cash_payment'] &&
          amount !== '0.00'
        }
      >
        <FormGroup style={{ marginTop: '5px', alignSelf: 'baseline' }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  selectedPsychDetails.psych_details['razorpay_account_id'] ==
                  null
                }
                checked={isCashPayment}
                onChange={() => onCashPaymentChange(!isCashPayment)}
              />
            }
            label="Pay later?"
          />
        </FormGroup>
      </VisibleContainer>
      <PackageDropdown
        value={aptType}
        marginTop={10}
        onChange={(value) => onAptTypeChange(value)}
        items={getTypeOfApt(
          selectedPsychDetails['psych_charges_with_packages']
        )}
        label="Appointment Type*"
      />
      {!!packageDesc && (
        <>
          <span style={{ marginTop: 3, color: 'GrayText', fontSize: 14 }}>
            <span style={{ color: 'black' }}>Description: </span>
            {isReadMore ? packageDesc : packageDesc.substring(0, 140)}
            <span
              onClick={() => setIsReadMore(!isReadMore)}
              className="read-more-btn"
            >
              {isReadMore ? 'Read less' : 'Read more...'}
            </span>
          </span>
        </>
      )}

      {getlocations(selectedPsychDetails['psych_location'])?.length > 0 ? (
        <SingleSelectDropdown
          value={locations}
          onChange={(value) => onLocationChange(value)}
          marginTop={10}
          placeholder="Select location (Optional)"
          items={getlocations(selectedPsychDetails['psych_location'])}
          label="Location"
        />
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  providerDetails: state.User.providerDetails,
});

const mapDispatchToProps = (dispatch) => ({
  showSpinner: dispatch.Component.showSpinner,
  hideSpinner: dispatch.Component.hideSpinner,
});

export default connect(mapStateToProps, mapDispatchToProps)(PreFormUI);
