import localDevVariables from "../localDevVariables";
const Envs = {
  app_region: 2,
  psychOnlineBaseUrl:
    localDevVariables.env === "staging"
      ? "https://apiupgrade.psychonline.com/api/v1" //STAGING
      : "https://apiupgradelive.psychonline.com/api/v1", //PRODUCTION
  psychOnlineBaseUrlV2:
    localDevVariables.env === "staging"
      ? "https://apiupgrade.psychonline.com/api/v2" //STAGING
      : "https://apiupgradelive.psychonline.com/api/v2", //PRODUCTION
  psychOnlineBaseUrlV3:
    localDevVariables.env === "staging"
      ? "https://staging.api.v2.psychonline.com/api/v3" //STAGING
      : "https://api.v2.psychonline.com/api/v3", //PRODUCTION
};

export default Envs;
