export default {
  state: {
    snackbarType: "",
    snackbarLabel: "",
    isSnackbarVisible: false,
    isBackdropLoaderVisible: false,
  },
  /**
   * Reducers
   */
  reducers: {
    backdropSpinnerReducer(state, value) {
      return {
        ...state,
        isBackdropLoaderVisible: value,
      };
    },
    snackbarReducer(state, data) {
      return {
        ...state,
        isSnackbarVisible: data["value"],
        snackbarLabel: data["label"],
        snackbarType: data["type"],
      };
    },
  },

  /**
   * Effects/Actions
   */
  effects: {
    async showSnackbar({ label, type }, state) {
      try {
        this.snackbarReducer({ value: true, label, type });
        if (!state.Component["isSnackbarVisible"]) {
          setTimeout(
            () => this.snackbarReducer({ value: false, label, type }),
            3000
          );
        }
      } catch (err) {
        console.log("Err showSnackbar: ", err);
      }
    },
    async showSpinner() {
      try {
        this.backdropSpinnerReducer(true);
      } catch (err) {
        console.log("Err showSpinner: ", err);
      }
    },
    async hideSpinner() {
      try {
        this.backdropSpinnerReducer(false);
      } catch (err) {
        console.log("Err hideSpinner: ", err);
      }
    },
  },
};
