import React from "react";
import { connect } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import "./BackdropSpinner.css";

const BackdropSpinner = ({ isBackdropLoaderVisible }) => {
  return (
    <Backdrop
      open={isBackdropLoaderVisible}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const mapStateToProps = (state) => ({
  isBackdropLoaderVisible: state.Component.isBackdropLoaderVisible,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BackdropSpinner);
