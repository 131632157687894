import React from 'react';
import {
  Card,
  Button,
  Grid,
  Box,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import './Loader.css';

const Loader = ({ label = 'loading...' }) => {
  return (
    <div className="loader-container">
      <div className="inner-loader-container">
        <CircularProgress />
        <p className="loader-text">{label && label}</p>
      </div>
    </div>
  );
};

export default Loader;
