import React from 'react';
import { Images } from '../config';
import './Success.css';

const Success = ({ title = 'Success' }) => {
  return (
    <div className="Success-container">
      <div className="inner-success-container">
        <img className="success-image" src={Images.checked2} alt="success" />
        <p className="success-text">{title && title}</p>
      </div>
    </div>
  );
};

export default Success;
