import axios from 'axios';
import store from '../store';
import { Envs } from '../config';

export default {
  state: {},
  /**
   * Reducers
   */
  reducers: {},

  /**
   * Effects/Actions
   */
  effects: {},
};
