import React from "react";
import { Button } from "@mui/material";
import { ReplayCircleFilled } from "@mui/icons-material";
import { Images } from "../config";
import "./Error.css";

const Error = ({
  onReloadPress = () => {},
  showReloadButton,
  title = "Failed to load data, please try again after sometime.",
}) => {
  return (
    <div>
      <div className="inner-error-container">
        <img
          className="error-image"
          src={Images.apiCallError}
          alt="api-call-error"
        />
        <p className="error-text">{title && title}</p>
        {showReloadButton && (
          <Button
            onClick={() => onReloadPress()}
            variant="contained"
            endIcon={<ReplayCircleFilled />}
          >
            Reload
          </Button>
        )}
      </div>
    </div>
  );
};

export default Error;
