import React from "react";
import { connect } from "react-redux";
import { Snackbar as SnackbarMat, Alert } from "@mui/material";
import "./Snackbar.css";

const Snackbar = ({ snackbarLabel, snackbarType, isSnackbarVisible }) => {
  return (
    <SnackbarMat
      open={isSnackbarVisible}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={snackbarType ? snackbarType : "success"}>
        {snackbarLabel && snackbarLabel}
      </Alert>
    </SnackbarMat>
  );
};

const mapStateToProps = (state) => ({
  snackbarType: state.Component.snackbarType,
  snackbarLabel: state.Component.snackbarLabel,
  isSnackbarVisible: state.Component.isSnackbarVisible,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
