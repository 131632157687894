import React from 'react';
import { FormLabel } from '@mui/material';
import './IconLabel.css';

const IconLabel = ({ Icon, label, marginTop, marginBottom }) => {
  return (
    <div
      className="icon-label-parent-container"
      style={{ marginTop, marginBottom }}
    >
      {Icon}
      <div style={{ marginLeft: '5px' }}>{label && label}</div>
    </div>
  );
};

export default IconLabel;
