import React from 'react';
import { KeyboardBackspace } from '@mui/icons-material';
import './ArrowBackButton.css';
import { Colors } from '../config';

const ArrowBackButton = ({ onClick = () => {} }) => {
  return (
    <div className="back-arrow-div-container " onClick={() => onClick()}>
      <KeyboardBackspace />
    </div>
  );
};

export default ArrowBackButton;
